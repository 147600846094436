import { createRef, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ICenter } from '../interfaces';
import '../styles/App.css';

// import runtimeconfig.js runtime
declare var config: any;

type Props = {
  selectedCenter: ICenter | undefined;
  selectedLanguage: string;
};

type LocationState = {
  voucherId: string;
  performance: {
    id: string;
  };
  productId: string;
};

const Webshop = ({ selectedCenter, selectedLanguage }: Props) => {
  const iframeRef = createRef<HTMLIFrameElement>();
  const location = useLocation<LocationState>();

  useEffect(() => {
    if (typeof window?.Compeso?.updateOrInitializeIFrameResizer === 'function') {
      window.Compeso.updateOrInitializeIFrameResizer();
    }
    if (typeof window?.Compeso?.loadContent === 'function') {
      window.Compeso.loadContent(config.apiService.webshop.endpoint);
    }
  }, [typeof window?.Compeso?.loadContent]);

  const getIFrameData = (pathname: string) => {
    let url = `${config.apiService.webshop.endpoint}/landingpage?center=${selectedCenter?.winticketId}&language=${selectedLanguage}`;

    switch (pathname) {
      case 'performance-list':
        url = `${url}&page=home`;
        break;
      case 'product-list':
        url = `${url}&page=productlist`;
        break;
      case 'withdialog/product-list':
        url = `${url}&page=productlist&displayoption=dialog&closeurl=${window.location.origin}/withdialog/product-list`;
        break;
      case 'retail-list':
        url = `${url}&page=retaillist`;
        break;
      case 'withdialog/retail-list':
        url = `${url}&page=retaillist&displayoption=dialog&closeurl=${window.location.origin}/withdialog/retail-list`;
        break;
      case 'retail-product':
        if (!location.state?.productId) {
          return <Redirect to='/retail-details' />;
        }
        url = `${url}&page=retailproduct&productId=${location.state?.productId}`;
        break;
      case 'registration':
      case 'withdialog/registration':
        url = `${url}&page=registration`;
        break;
      case 'customerverify':
        url = `${url}&page=customerverify`;
        break;
      case 'login':
      case 'withdialog/login':
        url = `${url}&page=login`;
        break;
      case 'logout':
      case 'withdialog/logout':
        url = `${url}&page=logout`;
        break;
      case 'my-account':
      case 'withdialog/my-account':
        url = `${url}&page=myaccount`;
        break;
      case 'loyalty-rewards':
        url = `${url}&page=loyaltyrewards`;
        break;
      case 'transaction-history':
      case 'withdialog/transaction-history':
        url = `${url}&page=transactionhistory`;
        break;
      case 'purchase-voucher':
        if (!location.state?.voucherId) {
          return <Redirect to='/voucher-list' />;
        }
        url = `${url}&page=voucherdetails&voucherId=${location.state?.voucherId}`;
        break;
      case 'seatingplan':
        if (!location.state?.performance?.id) {
          return <Redirect to='/' />;
        }
        url = `${url}&page=seatingplan&performance=${location.state.performance.id}`;
        break;
      case 'checkout':
      case 'withdialog/checkout':
        url = `${url}&page=checkout`;
        break;
      case 'privacy':
      case 'withdialog/privacy':
        url = `${url}&page=privacy`;
        break;
      case 'imprint':
      case 'withdialog/imprint':
        url = `${url}&page=imprint`;
        break;
      case 'faq':
        url = `${url}&page=faq`;
        break;
      case 'contact':
      case 'withdialog/contact':
        url = `${url}&page=contact`;
        break;
      case 'terms':
      case 'withdialog/terms':
        url = `${url}&page=terms`;
        break;
      case 'loyalty':
        url = `${url}&page=loyalty`;
        break;
      case 'loyaltydetails':
        url = `${url}&page=loyaltydetails`;
        break;
      case 'voucher-verification':
        url = `${url}&page=voucherverification`;
        break;
      case 'consents':
        url = `${url}&page=consents`;
        break;
      case 'newsletter':
        url = `${url}&page=newsletter`;
        break;
      case 'withdialog':
        url = `${url}&page=home&displayoption=dialog&closeurl=${window.location.origin}/withdialog`;
        break;
      case '':
        url = `${url}&page=home`;
        break;
      default:
        url = '';
        break;
    }

    return (
      <div className='container'>
        <div className='frame'>
          <iframe
            id='compeso-performance'
            className='iframe-webshop'
            title='iframe-webshop'
            src={url}
            ref={iframeRef}
            allow='payment; camera; clipboard-read; clipboard-write'
          >
            iframe content
          </iframe>
        </div>
      </div>
    );
  };

  return getIFrameData(location.pathname.slice(1));
};

export default Webshop;
